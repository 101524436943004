import React, { useState, useEffect } from "react";
import {
  Box,
  Typography
} from "@mui/material";

function CompetitionCountdown() {
  

  return (
    <Box>
      <Typography>we will provide mobile app soon for this</Typography>
    </Box>
  );
}

export default CompetitionCountdown;
